import {
  insertInLocalDb,
  getResultFromLocalDb,
  bulkRemoveFromLocalDb,
} from "@socotec.io/vuex-orm-rxdb-bridge";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import { AosObservable } from "@/models";
const amosPerimeterApi = socioGrpcClient.amos_back.aos;
const amosPerimeterClient = amosPerimeterApi.PerimeterControllerPromiseClient;

export const usePerimeterReplication = (visitUuid) => {
  const getOfflineObservables = async () => {
    let offlineAosObservables = [];

    const perimeterResponse = await getResultFromLocalDb({
      collectionName: "user_perimeter",
      filters: { projectId: visitUuid },
    });
    if (
      perimeterResponse.length &&
      perimeterResponse[0].createdOrUpdatedOffline
    ) {
      const perimeter = perimeterResponse[0];
      offlineAosObservables = await getResultFromLocalDb({
        collectionName: "aos_observable",
        uuidFilter: perimeter.aosObservableUuids,
      });

      offlineAosObservables = offlineAosObservables.filter(
        ({ createdOrUpdatedOffline }) => createdOrUpdatedOffline
      );
    }
    return offlineAosObservables;
  };

  const pullVisitPerimeter = async () => {
    let onlinePerimeter = null;
    try {
      const request = new amosPerimeterApi.RetrieveProjectPerimeterRequest();
      request.setProjectUuid(visitUuid);

      const response = await amosPerimeterClient.retrieveProjectPerimeter(
        request,
        {}
      );

      onlinePerimeter = response.toObject();
    } catch (e) {
      console.error(
        "Error pulling visit perimeter in perimeter replication protocol",
        e
      );
      return [];
    }

    const createdOfflineAosObserbable = await getOfflineObservables();
    await insertVisitPerimeter({
      ...onlinePerimeter,
      projectId: visitUuid,
      aosObservableUuids: [
        ...onlinePerimeter.aosObservablesList,
        ...createdOfflineAosObserbable,
      ].map(({ uuid }) => uuid),
      aosItemUuids: [
        ...onlinePerimeter.aosObservablesList,
        ...createdOfflineAosObserbable,
      ].map(({ aosItem }) => aosItem),
      createdOrUpdatedOffline: createdOfflineAosObserbable.length !== 0,
    });

    if (onlinePerimeter.aosObservablesList.length) {
      await AosObservable.insertOrUpdate({
        data: onlinePerimeter.aosObservablesList,
      });
    }
    return [onlinePerimeter];
  };

  const getAosObservableToSync = async (uuidList) => {
    let aosObservable = [];
    if (uuidList.length) {
      aosObservable = await getResultFromLocalDb({
        collectionName: "aos_observable",
        uuidFilter: uuidList,
      });
      aosObservable = aosObservable.filter(
        ({ createdOrUpdatedOffline }) => createdOrUpdatedOffline
      );
    }
    return aosObservable;
  };

  const getLocalAosObservableChanges = async (
    aosObservableWithoutComponent,
    importedComponent
  ) => {
    let localObsersableToRemove = [];
    let observableWithChangedAosItemUuid = [];
    for (let observable of aosObservableWithoutComponent) {
      if (importedComponent.length) {
        const component = importedComponent.find(
          ({ oldOfflineUuid }) => oldOfflineUuid === observable.aosItem
        );
        if (component) {
          observable.aosItem = component.uuid;
          observableWithChangedAosItemUuid.push(observable);
        } else {
          localObsersableToRemove.push(observable.uuid);
        }
      } else {
        localObsersableToRemove.push(observable.uuid);
      }
    }
    return { localObsersableToRemove, observableWithChangedAosItemUuid };
  };

  const getAosObservableRequest = async (
    localObservableToCreate,
    perimeterToSync
  ) => {
    const request = new socioGrpcClient.amos_back.aos.PerimeterResyncRequest();

    for (const localAoscomposable of localObservableToCreate) {
      const aosObservableRequest = socioGrpcClient.javascriptToRequest(
        socioGrpcClient.amos_back.aos.AosObservableResyncRequest,
        localAoscomposable
      );
      request.addAosObservables(aosObservableRequest);
    }

    let componentAssetToSync = await getResultFromLocalDb({
      collectionName: "component_asset",
      uuidFilter: localObservableToCreate.map(({ assetUuid }) => assetUuid),
    });

    for (const componentAsset of componentAssetToSync.filter(
      ({ createdOrUpdatedOffline }) => createdOrUpdatedOffline
    )) {
      const componentAssetRequest = socioGrpcClient.javascriptToRequest(
        socioGrpcClient.amos_back.aos.AssetRequest,
        {
          uuid: componentAsset.uuid,
          observationComment: componentAsset.observationComment,
          code: componentAsset.code,
        }
      );
      request.addAssets(componentAssetRequest);
    }
    request.setUuid(perimeterToSync.uuid);
    request.setCreatedAt(perimeterToSync.createdAt);
    request.setUpdatedAt(perimeterToSync.updatedAt);
    request.setIsArchived(perimeterToSync.isArchived);
    request.setSource(perimeterToSync.source);
    return request;
  };
  const getUniqueAosObservable = (
    aosObservableWithComponent,
    observableWithChangedAosItemUuid
  ) => {
    const uniqueAosItemUuid = new Set();
    const localObservableToCreate = [
      ...aosObservableWithComponent,
      ...observableWithChangedAosItemUuid,
    ].filter((doc) => {
      if (uniqueAosItemUuid.has(doc.aosItem)) {
        return false;
      }
      uniqueAosItemUuid.add(doc.aosItem);
      return true;
    });
    return localObservableToCreate;
  };
  const getDebugMetadata = (
    aosObservableToCreate,
    observableWithChangedAosItemUuid,
    localObsersableToRemove,
    successComponent,
    duplicateComponent
  ) => {
    let debugData = {};
    if (successComponent.length || duplicateComponent.length) {
      debugData.aosItem = {};
    }
    if (
      aosObservableToCreate.length ||
      observableWithChangedAosItemUuid ||
      localObsersableToRemove
    ) {
      debugData.localAosObservable = {};
    }
    for (const component of [...successComponent, ...duplicateComponent]) {
      debugData.aosItem[component.uuid] = {
        ...component,
      };
    }
    for (const aosObs of aosObservableToCreate) {
      debugData.localAosObservable[aosObs.uuid] = {
        ...aosObs,
        typeOfAosObs: "no-problem",
      };
    }
    for (const aosObs of observableWithChangedAosItemUuid) {
      debugData.localAosObservable[aosObs.uuid] = {
        ...aosObs,
        typeOfAosObs: "aosItem uuid was updated front-side",
      };
    }
    for (const aosObs of localObsersableToRemove) {
      debugData.localAosObservable[aosObs.uuid] = {
        ...aosObs,
        typeOfAosObs: "was delete front side",
      };
    }
    return { filters: JSON.stringify({ debug_data: debugData }) };
  };

  const pushVisitPerimeter = async (document) => {
    for (const perimeterToSync of document) {
      const aosObservableToSync = await getAosObservableToSync(
        perimeterToSync.aosObservableUuids
      );
      if (!aosObservableToSync.length) continue; // if empty, nothing to resync

      const aosItemDependenciesUuidList = aosObservableToSync.map(
        ({ aosItem }) => aosItem
      );

      const componentCreated = await getResultFromLocalDb({
        collectionName: "aos_component",
        uuidFilter: aosItemDependenciesUuidList,
      });

      let aosObservableWithComponent = aosObservableToSync.filter(
        ({ aosItem }) =>
          componentCreated.map(({ uuid }) => uuid).includes(aosItem)
      );

      let aosObservableWithoutComponent = aosObservableToSync.filter(
        ({ aosItem }) =>
          !componentCreated.map(({ uuid }) => uuid).includes(aosItem)
      );

      const componentImported = aosObservableWithoutComponent.length
        ? await getResultFromLocalDb({
            collectionName: "aos_component",
            inArrayFilter: {
              column: "oldOfflineUuid",
              list: aosObservableWithoutComponent.map(({ aosItem }) => aosItem),
            },
          })
        : [];

      if (
        [...componentCreated, ...componentImported].some(
          ({ createdOrUpdatedOffline }) => createdOrUpdatedOffline === true
        )
      ) {
        throw new Error("Replication push: must wait resync");
      }
      const { localObsersableToRemove, observableWithChangedAosItemUuid } =
        await getLocalAosObservableChanges(
          aosObservableWithoutComponent,
          componentImported
        );

      const localObservableToCreate = getUniqueAosObservable(
        aosObservableWithComponent,
        observableWithChangedAosItemUuid
      );

      const request = await getAosObservableRequest(
        localObservableToCreate,
        perimeterToSync
      );
      const metadata = getDebugMetadata(
        aosObservableWithComponent,
        observableWithChangedAosItemUuid,
        localObsersableToRemove,
        componentCreated,
        componentImported
      );

      const reponse =
        await socioGrpcClient.amos_back.aos.PerimeterControllerPromiseClient.resyncPerimeter(
          request,
          metadata
        );

      debugLogResync(aosObservableToSync, reponse.toObject().resultsList);
      delete perimeterToSync.deleted;
      await AosObservable.insertOrUpdate({
        data: localObservableToCreate.map((aosObservable) => {
          return {
            ...aosObservable,
            createdOrUpdatedOffline: false,
          };
        }),
      });
      if (localObsersableToRemove.length)
        await bulkRemoveFromLocalDb({
          collectionName: "aos_observable",
          primaryKeys: localObsersableToRemove,
        });
      await insertVisitPerimeter({
        ...perimeterToSync,
        createdOrUpdatedOffline: false,
      });
    }
    return [];
  };

  const debugLogResync = (aosObservableToSync, responseList) => {
    const mapDocByUuid = new Map(
      aosObservableToSync.map((observable) => [observable.uuid, observable])
    );
    let message = "\nJe suis le rapport coté amos !\n";
    const failureList = responseList.filter(
      ({ status }) => status === "failure"
    );
    const successList = responseList.filter(
      ({ status }) => status === "success"
    );
    message += successList.length
      ? `${successList.length} création de composent amos réussis\n`
      : "Aucune création réussi\n";
    for (const success of successList) {
      let concernedObservable = mapDocByUuid.get(success.uuid);
      message += `- Observablié lié a l'element aos : ${concernedObservable.aosItem} || uuid: ${concernedObservable.uuid} \n`;
    }
    message += failureList.length
      ? `${failureList.length} création de composent amos en ECHEC \n`
      : "Aucune création en échec\n";

    for (const failure of failureList) {
      let concernedObservable = mapDocByUuid.get(failure.uuid);
      message += `- Observablié lié a l'element aos : ${concernedObservable.aosItem} || uuid: ${concernedObservable.uuid} || reason: ${failure.reason}\n`;
    }
    console.log(message);
  };
  const insertVisitPerimeter = async (perimeter) => {
    const result = await insertInLocalDb({
      collectionName: "user_perimeter",
      dataToInsert: [perimeter],
      keysToIgnore: ["aosObservablesList", "source"],
      debug: true,
    });
    return result;
  };

  return {
    pullVisitPerimeter,
    pushVisitPerimeter,
    insertVisitPerimeter,
  };
};
