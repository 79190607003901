import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./setup/i18n";
import vuetify from "./setup/vuetify";
import VueSignaturePad from "vue-signature-pad";
import wb from "./registerServiceWorker";
import { initializeAuthHooks } from "@/setup/auth-hooks";
import { socioGrpcClient } from "@/setup/socioGrpcClient";
import SocioVueComponents, {
  initializeTracingAndLogging,
} from "@socotec.io/socio-vue-components";
import AosComponents from "@socotec.io/socio-aos-component";
import "./directives/v-socio-permissions";

import SocioManifest from "../socio-manifest.json";
import PackageJson from "../package.json";
import "@/setup/grpc-web-extension.js";

initializeTracingAndLogging(SocioManifest, PackageJson, {
  maxExportBatchSize: 10,
  router,
});

if (window.Worker) {
  const worker = new Worker(
    new URL("./workers/rxdb-worker.js", import.meta.url)
  );
  Vue.prototype.$rxdbWorker = worker;
}

Vue.prototype.$workbox = wb;

Vue.config.productionTip = false;

Vue.use(SocioVueComponents);

store.$client = socioGrpcClient;
const envConfig = {
  i18nLocale: process.env.VUE_APP_I18N_LOCALE,
  i18nFallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  mapBoxApiKey: process.env.VUE_APP_MAP_BOX,
};
Vue.use(AosComponents, { store, envConfig });

Vue.use(VueSignaturePad);

initializeAuthHooks();

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
